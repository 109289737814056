import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import 'bootstrap';
import VeeValidate from 'vee-validate';
import Loading from 'vue-loading-overlay';
import VueSweetalert2 from 'vue-sweetalert2';
import OverlayScrollbars from 'overlayscrollbars';
import { OverlayScrollbarsPlugin } from 'overlayscrollbars-vue';
import CKEditor from '@ckeditor/ckeditor5-vue';
import draggable from 'vuedraggable';
import VueGtag from 'vue-gtag';
import App from './App.vue';
import router from './router';
import store from './store';
import { API_GET_LOGINSTATUS } from './plugins/api';

import 'vue-loading-overlay/dist/vue-loading.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import currencyFilter from './plugins/currency';
import './registerServiceWorker';

Vue.use(VeeValidate);
Vue.use(Loading);
Vue.use(VueSweetalert2);
Vue.use(VueAxios, axios);
Vue.use(CKEditor);
Vue.use(OverlayScrollbarsPlugin);
Vue.component('Loading', Loading);
Vue.component('Draggable', draggable);
Vue.filter('currency', currencyFilter);

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  /* 測試 ID */
  config: { id: 'G-NLZYSTL1NQ' },
  /* 正式 ID */
  // config: { id: 'G-8XHJJZ6M01' },
}, router);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

OverlayScrollbars(document.body, {
  nativeScrollbarsOverlaid: {
    initialize: false,
  },
});

/* 登入驗證 */
router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth) {
    const response = await API_GET_LOGINSTATUS();
    const res = response.data;
    if (res.code === '0000') {
      next();
    }
  } else {
    next();
  }
});
